$tbx_green: #A2AD00;
$tbx_green_light: #e3e6b2;
$tbx_orange: #E37222;
$tbx_orange_light: #f7d4bc;
$tbx_blue: #003359;
$tbx_blue_light: #b2c1cd;
$tbx_violet: #A82584;
$tbx_violet_light: #e5bdda;
$tum_blue: #3070b3;

body {
    padding-top: 100px;
    min-height:100vh;
}




html {
    overflow-y:scroll;
}




.tooltip {
    z-index: 300;
}





.footer-link {
    color: black !important;
}
.footer-link:hover {
    color: black !important;
    text-decoration: none !important;
}


.problem-link {
    color: black !important;
}
.problem-link:hover {
    color: $tum_blue !important;
    text-decoration: none !important;
}




#problemTab {
    border-color: #a0a0a0 !important;
}


#problemTab .active {
    color: $tum_blue !important;
    border-color: #a0a0a0 !important;
    border-bottom-color: white !important;
}

#problemTab .nav-link:not(.active):hover {
    border-bottom: none !important;
}





.vis-card {
    padding: 10px;
    border-radius: 15px;
    background-color: white;
    border-width: 1pt;
    border-color: #ccc;
    transition-duration: 0.1s;
}

.vis-card:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 4px;
    transform: translateX(-2px) translateY(-8px);
    transition-duration: 0.1s;
}








.highlighter-rouge {
    background-color: rgba(245,245,245,1);
    padding: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #000000;
}







.tbx-video {
    margin: 15px;
    max-width: 1100px;
}

.tbx-transcript {
    height: 800px !important;
}


.frametrail-iframe {
    width: 100%; /* Take up all available width by default */
    max-width: 900px; /* Maximum width of 1000 pixels */
    height: 600px; /* Fixed height of 600 pixels, adjust as needed */
  }
  
  /* Media query for screens wider than 1000px */
  @media (min-width: 1000px) {
    .frametrail-iframe {
      width: 900px; /* Set width to 1000 pixels if the viewport is at least 1000px wide */
    }
  }


// .able {
//     padding: 10px !important;
//     background-color: white !important;
//     box-shadow: none !important;
// }

// .able-status-bar {
//     padding-bottom: 30px !important;
// }

// .able-left-controls, .able-right-controls {
//     margin: 10px !important;
// }





.course-navigation * {
    background: none;
    border: 2px solid #ffffff00;
    text-align: left;
}
.course-navigation .accordion-body .accordion-item {
    margin-top: 4px;
    margin-bottom: 4px;
}

.course-navigation .accordion-body {
    margin-top: -10px !important;
}

.course-navigation .accordion-header .btn {
    display: block;
}


.course-navigation .btn {
    color: black;
}
.course-navigation .btn:focus {
    outline: none !important;
    box-shadow: none !important;
}



.course-navigation .accordion-button {
    border: none;
    padding: 8px;
}

.course-navigation .btn {
    padding: 4px;
}

.course-navigation .accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
}


.course-navigation #outer-head-goals:hover {
    border: 2px solid grey;
}
.course-navigation #outer-head-ezw:hover {
    border: 2px solid $tbx_green;
}
.course-navigation #outer-head-fd:hover {
    border: 2px solid $tbx_orange;
}
.course-navigation #outer-head-fw:hover {
    border: 2px solid $tbx_blue;
}
.course-navigation #outer-head-extra:hover {
    border: 2px solid $tbx_violet;
}
.course-navigation #outer-head-videos:hover {
    border: 2px solid grey;
}
.course-navigation #outer-head-problems:hover {
    border: 2px solid grey;
}

.course-navigation #outer-head-goals .collapsed, .course-navigation #outer-head-ezw .collapsed, .course-navigation #outer-head-fd .collapsed, .course-navigation #outer-head-fw .collapsed, .course-navigation #outer-head-extra .collapsed, .course-navigation #outer-head-videos .collapsed, .course-navigation #outer-head-problems .collapsed {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 100%);
}

.course-navigation [id^="outer-head-"] .collapsed {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 100%);
}

h2.current-entry {
    background-color: lightgray;
}
h2.current-page {
    background-color: lightgray;
}


.course-navigation #outer-head-goals .accordion-button {
    font-weight: bold;
    color: #565656;
}
.course-navigation #outer-head-ezw .accordion-button {
    font-weight: bold;
    color: $tbx_green;
}

.course-navigation #outer-head-fd .accordion-button {
    font-weight: bold;
    color: $tbx_orange;
}

.course-navigation #outer-head-fw .accordion-button {
    font-weight: bold;
    color: $tbx_blue;
}
.course-navigation #outer-head-extra .accordion-button {
    font-weight: bold;
    color: $tbx_violet;
}

.course-navigation #outer-head-videos .accordion-button {
    font-weight: bold;
    color: #565656;
}

.course-navigation #outer-head-problems .accordion-button {
    font-weight: bold;
    color: #565656;
}

.course-navigation .accordion-button:not(.collapsed) {
    color: black;
    background-color: white;
}

.course-navigation .accordion-button::after {
    transform: rotate(90deg) !important;
}
.course-navigation .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    transform: rotate(0deg) !important;
}










.course-navigation .accordion-item .accordion-header {
    color: black;
    //background-color: white;
    
}
.course-navigation .tbx-goals .accordion-item .accordion-header:hover {
    border: 2px solid grey;
}
.course-navigation .tbx-ezw .accordion-item .accordion-header:hover {
    border: 2px solid $tbx_green;
}
.course-navigation .tbx-fd .accordion-item .accordion-header:hover {
    border: 2px solid $tbx_orange;
}
.course-navigation .tbx-fw .accordion-item .accordion-header:hover {
    border: 2px solid $tbx_blue;
}
.course-navigation .tbx-extra .accordion-item .accordion-header:hover {
    border: 2px solid $tbx_violet;
}
.course-navigation .tbx-videos .accordion-item .accordion-header:hover {
    border: 2px solid grey;
}
.course-navigation .tbx-problems .accordion-item .accordion-header:hover {
    border: 2px solid grey;
}

.course-navigation .tbx-goals .current-page {
    background-color: lightgrey;
}
.course-navigation .tbx-goals .current-entry {
    background-color: lightgrey;
}
.course-navigation .tbx-goals .current-page-parent {
    background-color: lightgrey;
}
.course-navigation .tbx-ezw .current-page {
    background-color: $tbx_green_light;
}
.course-navigation .tbx-ezw .current-entry {
    background-color: $tbx_green_light;
}
.course-navigation .tbx-ezw .current-page-parent {
    background-color: $tbx_green_light;
}
.course-navigation .tbx-fd .current-page {
    background-color: $tbx_orange_light;
}
.course-navigation .tbx-fd .current-entry {
    background-color: $tbx_orange_light;
}
.course-navigation .tbx-fd .current-page-parent {
    background-color: $tbx_orange_light;
}
.course-navigation .tbx-fw .current-page {
    background-color: $tbx_blue_light;
}
.course-navigation .tbx-fw .current-entry {
    background-color: $tbx_blue_light;
}
.course-navigation .tbx-fw .current-page-parent {
    background-color: $tbx_blue_light;
}
.course-navigation .tbx-extra .current-page {
    background-color: $tbx_violet_light;
}
.course-navigation .tbx-extra .current-entry {
    background-color: $tbx_violet_light;
}
.course-navigation .tbx-extra .current-page-parent {
    background-color: $tbx_violet_light;
}
.course-navigation .tbx-videos .current-page {
    background-color: lightgrey;
}
.course-navigation .tbx-videos .current-entry {
    background-color: lightgrey;
}
.course-navigation .tbx-videos .current-page-parent {
    background-color: lightgrey;
}
.course-navigation .tbx-problems .current-page {
    background-color: lightgrey;
}
.course-navigation .tbx-problems .current-entry {
    background-color: lightgrey;
}
.course-navigation .tbx-problems .current-page-parent {
    background-color: lightgrey;
}

.course-navigation .accordion-button:not(.collapsed) {
    background: none !important;
}

#accordion-goals .accordion-item:has(> :nth-child(2).show), #accordion-goals .accordion-item:has(> :nth-child(2).collapsing), #accordion-goals .accordion-item:has(> :first-child.current-page-parent) {
    border: 2px solid lightgrey;
}
#accordion-ezw .accordion-item:has(> :nth-child(2).show), #accordion-ezw .accordion-item:has(> :nth-child(2).collapsing), #accordion-ezw .accordion-item:has(> :first-child.current-page-parent) {
    border: 2px solid $tbx_green_light;
}
#accordion-fd .accordion-item:has(> :nth-child(2).show), #accordion-fd .accordion-item:has(> :nth-child(2).collapsing), #accordion-fd .accordion-item:has(> :first-child.current-page-parent) {
    border: 2px solid $tbx_orange_light;
}
#accordion-fw .accordion-item:has(> :nth-child(2).show), #accordion-fw .accordion-item:has(> :nth-child(2).collapsing), #accordion-fw .accordion-item:has(> :first-child.current-page-parent) {
    border: 2px solid $tbx_blue_light;
}
#accordion-extra .accordion-item:has(> :nth-child(2).show), #accordion-extra .accordion-item:has(> :nth-child(2).collapsing), #accordion-extra .accordion-item:has(> :first-child.current-page-parent) {
    border: 2px solid $tbx_blue_light;
}
#accordion-videos .accordion-item:has(> :nth-child(2).show), #accordion-videos .accordion-item:has(> :nth-child(2).collapsing), #accordion-videos .accordion-item:has(> :first-child.current-page-parent) {
    border: 2px solid lightgrey;
}
#accordion-problems .accordion-item:has(> :nth-child(2).show), #accordion-problems .accordion-item:has(> :nth-child(2).collapsing), #accordion-problems .accordion-item:has(> :first-child.current-page-parent) {
    border: 2px solid lightgrey;
}






.has-pale-pink-background-color {
    background-color: #f78da7 !important;
}
.has-pale-cyan-blue-background-color {
    background-color: #8ed1fc !important;
}
.has-cyan-bluish-gray-background-color {
    background-color: #abb8c3 !important;
}
div {
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
}
@media (min-width: 782px) {
    .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
        flex-basis: 0;
        flex-grow: 1;
    }
}
:where(.wp-block-columns.is-layout-flex) {
    gap: 2em !important;
}
.has-primary-color {
    color: #0073aa !important;
}
.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column[style*=flex-basis] {
    flex-grow: 0;
}
:root :where(p.has-background) {
    padding: 1.25em 2.375em;
}
.is-layout-flex > :is(*, div) {
    margin: 0;
    flex-grow: 1;
}
.alignleft {
    float: left;
}
h3, h6 {
    color: #3070b3;
}
.wp-block-column.is-vertically-aligned-center {
    align-self: center;
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
}
/* .row > * {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: calc(var(--bs-gutter-x) / 2);
    margin-left: calc(var(--bs-gutter-x) / 2);
} */
.wp-block-column {
    // flex-grow: 1;
    min-width: 0;
    overflow-wrap: break-word;
    word-break: break-word;
}
.wp-block-table thead {
    border-bottom: 3px solid;
}
.wp-block-table td, .wp-block-table th {
    padding: .5em;
}
.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
    background-color: #f0f0f0;
}
.alignright {
    text-align: right;
}
figure.alignright {
    float: right;
    margin-left: 1rem;
}
.wp-block-columns {
    align-items: normal !important;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap !important;
}
.has-text-align-center {
    text-align: center;
}
body .is-layout-flex {
    display: flex;
}
.wp-container-core-group-is-layout-1 {
    flex-wrap: nowrap;
}
.is-layout-flex {
    flex-wrap: wrap;
    align-items: center;
}
.wp-block-group {
    box-sizing: border-box;
}
:where(.is-layout-flex) {
    gap: 0.5em;
}
.wp-block-image img {
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
}
/* Gesamtes Akkordeon (äußerer Container) */
.akkordeon {
    max-width: 800px; /* Beispielbreite – anpassen wie gewünscht */
    margin: 0 auto;
  }
  
  /* Grundgestaltung des Details‑Blocks */
  .akkordeon .wp-block-details {
    background: #F0F8FF;
    border: 1px solid var(--wp--preset--color--contrast-3, #ccc);
    border-radius: 0.33rem;
    transition: all 0.5s ease-in-out;
  }
  .akkordeon .wp-block-details p {
      margin-bottom: 0 !important;
  }
  
  .social-direct-link {
    background: #F0F8FF; 
    padding: 1rem 2rem !important;
    margin: 0 !important;
  }
  .social-direct-link a {
      text-decoration: none;
      font-weight: 400;
      color: #111;
      padding: 1px 0.25rem;
      border: 1px solid #0185d2;
      border-radius: 0.33rem;
  }
  
  .akkordeon .wp-block-details p:last-of-type {
      margin-bottom: 0;
  }
  
  /* Gestaltung der Zusammenfassung im Details‑Block */
  .akkordeon .wp-block-details summary {
    transition: all 0.5s ease-in-out;
    box-sizing: border-box;
    padding: 1rem;
    font-weight: 500;
    cursor: pointer;
  }
  
  .akkordeon .wp-block-details summary:hover,
  .akkordeon .wp-block-details summary:focus,
  .akkordeon .wp-block-details[open] summary {
    background: #0072B8;
	color: white;
  }
  
  /* Inhalt (alles unterhalb der summary) */
  .akkordeon .wp-block-details > :where(:not(summary)) {
    margin-left: 2rem;
    margin-right: 2rem;
    padding: 1rem 0;
  }
  
  /* Styling für die Definition List */
  .company-details {
    display: grid;
    grid-template-columns: max-content auto;
    gap: 0.5rem 1rem;
    margin: 0 2rem !important;
    padding: 1rem 0 0 0 !important;
  }
  
  .company-details dt {
    font-weight: bold;
    /* Optional: Textfarbe oder andere Styles für die Feldnamen */
  }
  
  .company-details dd {
    margin: 0;
  }
.pitch-.pitch-table {
	width: 100%;
	border-collapse: collapse;
}
.pitch-table th,
.pitch-table td {
	border: 1px solid #ccc;
	padding: 15px;
	text-align: left;
	vertical-align: top;
}
.pitch-table th {
	background-color: #f2f2f2;
}
.pitch-table .checkmark {
	background-color: #e8f5e9;
}
.pitch-table .crossmark {
	background-color: #fdecea;
}
.pitch-table ul {
	margin: 0;
	padding-left: 20px;
}
body .is-layout-flex {
    display: flex;
}
.wp-container-core-group-is-layout-1 {
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.is-layout-flex {
    flex-wrap: wrap;
    align-items: center;
}
.wp-block-group {
    box-sizing: border-box;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.wp-block-group-is-layout-flex > p {
	width: 84%;
}
figure {
    margin: 0 0 1rem;
}
figure.aligncenter {
    max-width: 80%;
}
.wp-block-image img {
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
}
img {
    overflow-clip-margin: content-box;
    overflow: clip;
    max-width: 100%;
}
.wp-block-image.aligncenter {
    text-align: center;
}
.aligncenter {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}
.hint-box {
    background-color: #f0f8ff;
    border-left: 4px solid #0072b8;
    padding: 15px;
    margin: 20px 0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.hint-box p:last-of-type {
    margin-bottom: 0;
}

.content-container {
    overflow: hidden;
}
.floating-box {
    width: 35%;
    background-color: #e6f0f7;
    border: 2px solid #0072b8;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    color: #333;
}
.floating-box p:last-of-type {
    margin-bottom: 0;
}
.floating-box strong {
    color: #0072b8;
}
.text-content {
    text-align: justify;
}
/* Bootstrap extension */
.bg-darkbrown {
	background-color: #A75225;
}
.bg-brown {
	background-color: #F2AA84;
}
.bg-darkblue {
	background-color: #2B5B71;
}
.bg-blue {
	background-color: #5AAFE1;
}
.bg-darkgreen {
	background-color: #2E551E;
}
.bg-green {
	background-color: #8DE08C;
}
.bg-darkpurple {
	background-color: #762B72;
}
.bg-purple {
	background-color: #DE9CDC;
}
.bg-darkgray {
	background-color: #817E7E;
}
.bg-gray {
	background-color: #BDBDBD;
}
.bg-darkyellow {
	background-color: #B9B80A;
}
.bg-yellow {
	background-color: #E2E300;
}
.border-blue {
	border: 1px solid #4472C4;
}
.center-vertically {
    display: flex;
    align-items: center;
}
div.container > div.container-fluid > div.row.d-flex {
	flex-wrap: nowrap;
}
@media (max-width: 768px) {
	div.container > div.container-fluid > div.row.d-flex {
		flex-wrap: wrap;
	}
}
.dt-container-header {
	position: relative;
	display: inline-block;
	color: #FFFDF0;
	font-size: 28px;
    border-radius: 50px;
    padding: 1.5rem 2rem;
	margin-bottom: -2rem;
	z-index: 2;
}
.dt-container {
	position: relative;
	color: #000000;
	font-size: 18px;
	border-radius: 15px;
	padding: 4rem 2rem 2rem 2rem;
	margin-top: -1rem;
	z-index: 1;
}
.brown-container li {
	margin-left: 1.5rem;
}




/* Container for tab buttons */
.mini-challenge-page .tab-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}

/* Tab buttons styling */
.mini-challenge-page .tab-btn {
    background-color: #0075bc;
    color: white;
    padding: 15px 0;
    flex-grow: 1;
    text-align: center;
    cursor: pointer;
    border: none;
    font-size: 16px;
    transition: background-color 0.3s ease;
    border-right: 1px solid #004070;
}

/* Remove last border-right to avoid overflow */
.mini-challenge-page .tab-btn:last-child {
    border-right: none;
}

/* Hover effect on buttons */
.mini-challenge-page .tab-btn:hover {
    background-color: #005a91;
}

/* Active tab button */
.mini-challenge-page .tab-btn.active {
    background-color: #005a91;
}

/* Content areas for the tabs */
.mini-challenge-page .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    margin-top: -1px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

/* Add a subtle background color to the active content */
.mini-challenge-page .tab-content.active {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mini-challenge-page .tab-content h4 {
    margin: 0 0 20px 0;
}

/* Flexbox for side-by-side Zielgruppe, Lernziele, Materialien, and Dauer */
.mini-challenge-page .side-by-side-container {
    display: flex;
    align-items: flex-start; /* Aligns items at the top */
    gap: 20px;
}

.mini-challenge-page .side-by-side-container > div {
    flex: 1;
}

/* h3 styling */
.mini-challenge-page h3 {
    color: #005a91; /* Set the same blue as h4 */
    margin-top: 20px; /* Larger margin for top separation */
    margin-bottom: 25px; /* Increase bottom margin for better spacing */
}

/* h4 styling */
.mini-challenge-page h4 {
    color: #005a91;
    margin-top: 30px; /* Increase margin at the top for better separation */
    margin-bottom: 15px;
}

.mini-challenge-page p {
    font-size: 16px;
    line-height: 1.7;
    color: #333;
}

.mini-challenge-page ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
}

.mini-challenge-page a {
    color: #0075bc;
    text-decoration: underline;
}

/* Remove margins between tab content and the buttons */
.mini-challenge-page .tab-container + .tab-content {
    margin-top: 0;
}

/* Hover effect on buttons */
.mini-challenge-page button:hover {
    background-color: #005a91;
}





.tbx-img-center {
    text-align: center;
}

.tbx-img-larger {
    display: block;
    max-width: 1100px;
    margin: auto;
}

.tbx-img-large {
    display: block;
    max-width: 800px;
    margin: auto;
}

.tbx-img-small {
    display: block;
    max-width: 600px;
    margin: auto;
}

.tbx-img-smaller {
    display: block;
    max-width: 400px;
    margin: auto;
}

.tbx-img-300w {
    display: block;
    max-width: 300px;
    margin: auto;
}

.tbx-img-150w {
    display: block;
    max-width: 150px;
    margin: auto;
}

.img-container img {
    max-width: 100%; /* Ensures the image doesn't exceed its container */
    height: auto; /* Maintains the aspect ratio */
  }





.btn-outline-primary {
    color: $tum_blue;
    border-color: $tum_blue;
  }
  .btn-outline-primary:hover {
    background-color: $tum_blue;
    border-color: $tum_blue;
  }
  .btn-outline-primary:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  
  

.tbx-question-comment {
    color: grey;
}






#module-accordion .accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: lightgrey;
}
#module-accordion .accordion-button:not(.collapsed) {
    background: none !important;
    outline: none !important;
    box-shadow: none !important;
}

#module-accordion .accordion-button::after {
    transform: rotate(90deg) !important;
}
#module-accordion .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    transform: rotate(0deg) !important;
}




.module-accordion .accordion-button, .module-accordion .show, .module-accordion .collapsing {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.module-accordion .accordion-button {
    padding: 5px !important;
}

.module-accordion .accordion-button:not(.collapsed) {
    background: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.module-accordion .accordion-button::after {
    transform: rotate(90deg) !important;
}
.module-accordion .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    transform: rotate(0deg) !important;
}



.module-accordion .collapsed {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 100%);
}





.aux-accordion .accordion-button, .aux-accordion .show, .aux-accordion .collapsing {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    color: #454545;
}

.aux-accordion .accordion-button {
    padding: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.aux-accordion .accordion-button:not(.collapsed) {
    background: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.aux-accordion .accordion-button::after {
    transform: rotate(90deg) !important;
}
.aux-accordion .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    transform: rotate(0deg) !important;
}



.aux-accordion .collapsed {
    background: linear-gradient(90deg, rgba(255,255,255,1) -20%, rgba(240,240,240,1) 100%);
}












.module-button {
    max-height: 50px;
    border-color: lightgrey;

}



.module-button:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: lightgrey;
}
.module-button:not(.collapsed) {
    background: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.module-button::after {
    transform: rotate(90deg) !important;
}
.module-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    transform: rotate(0deg) !important;
}

.module-accordion-body {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    
}






// .cindy-scaled {
//     width: 800px;
//     //height: 600px;
//     position: relative;
//     left: calc(50% - 400px);

// }
// @media screen and (max-width: 1170px) {
// .cindy-scaled {
//     zoom: 1;
//     -moz-transform: scale(0.8);
//     -moz-transform-origin: -100 0;
//     -o-transform: scale(0.8);
//     -o-transform-origin: -100 0;
//     -webkit-transform: scale(0.8);
//     -webkit-transform-origin: -100 0;  
//     -ms-transform: scale(0.8);
//     -ms-transform-origin: -100 0;  
//     transform: scale(0.8);
//     transform-origin: -100 0;  
//     border-width: 2px;
//     margin-top: -60px;
//     margin-bottom: -60px;
// }
// }
// @media screen and (max-width: 620px) {
// .cindy-scaled {
//     zoom: 1;
//     -moz-transform: scale(0.5);
//     -moz-transform-origin: -100 0;
//     -o-transform: scale(0.5);
//     -o-transform-origin: -100 0;
//     -webkit-transform: scale(0.5);
//     -webkit-transform-origin: -100 0; 
//     -ms-transform: scale(0.5);
//     -ms-transform-origin: -100 0; 
//     transform: scale(0.5);
//     transform-origin: -100 0; 
//     border-width: 3px; 
//     margin-top: -120px;
//     margin-bottom: -120px;
// }
// }  



.cindy-container {
    max-width: 1100px;
    overflow-x: hidden;

}









.footer {
    background-color: #e5e5e5 !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}



.flex-fill {
    flex: 1 1 auto;
}


.img-link + em  {
    font-size: smaller;
    display: block;
    text-align: center;
}




.navbar {
    background-color: $tum_blue;
    z-index: 200;
}

.nav-link {
    font-weight: bold;
    margin: 0 15px 0 15px;
}

.ezw {
    color: $tbx_green;
}

.fd {
    color: $tbx_orange;
}

.fw {
    color: $tbx_blue;
}

.extra {
    color: $tbx_violet;
}


.tbx-headline {
    color: $tum_blue;
    font-weight: bold;
    margin-top: 40px;
}

a {
    color: $tum_blue;
    text-decoration: none;
  }
a:link {
    color: $tum_blue;
}
a:hover {
    color: $tbx_green;
}

.module-card {
    text-decoration: none;
    transition-duration: 0.1s;
}

.module-card:hover {
    //background-color: rgba(0, 0, 0, 0.05);
    
    box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 4px;
    transform: translateX(-2px) translateY(-8px);
    transition-duration: 0.1s;
}






.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.7);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 1.0);
}
.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0);
}


.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler {
    border-width: 4px;
}




.tbx-impuls {
    width: 200px;
}







.solution-reveal .accordion-button {
    background-color: #eee;
    box-shadow: none !important;
    padding: 8px;
}

.solution-reveal .accordion-button:not(.collapsed) {
    background-color: #eee !important;
    color: black;
}

.solution-reveal .accordion-button:focus {
    border-color: #dfdfdf;
    color: black !important;
}

.solution-reveal .accordion-button::after {
    transform: rotate(90deg) !important;
}

.solution-reveal .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    transform: rotate(0deg) !important;
}






/* TUM footer */
.tumfooter {
    line-height: 1.2;
}

.tumfooter .links {
    background-color: #f7f7f7;
}

@media (min-width: 601px){
	.belt {
		margin: 0 auto;
		max-width: 1150px;
		width: 96%;
	}
}

@media (max-width: 600px){
	.fubar .belt, #page-body .org .belt, #normal, #sidebar, footer .belt {
		margin: 0 auto;
		width: 96%;
	}
}

.tumfooter .note {
    padding: 1.5em 0 0;
}

.tumfooter .note, .tumfooter .misc {
    font-size: .87em;
}

.tumfooter .navi {
    overflow: hidden;
    padding-bottom: 1em;
}

@media (min-width: 601px){
	.tumfooter .more.links {
		background-color: #f7f7f7;
	}
}

.tumfooter .more.links {
    background-color: #e5e5e5;
    padding: 1.4em 0;
}
.tumfooter .links {
    background-color: #f7f7f7;
}

.tumfooter ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navi ul.level_1>li {
    display: inline-block;
    width: 14.3%;
    min-width: 110px;
    margin: 1.8em 2% 0 0;
    padding: 0;
    vertical-align: top;
}

.tumfooter span.pagetitle a {
    margin-bottom: 1em;
    font-size: 86%;
    font-weight: bold;
    text-transform: uppercase;
}

.tumfooter a {
    display: block;
    color: #333;
    text-decoration: none;
}
a {
    background-color: transparent;
}

.navi ul.level_2>li {
    margin: 0 0 .8em 0;
    padding: 0;
    font-size: 85%;
}

.tumfooter .more.links {
    background-color: #e5e5e5;
    padding: 1.4em 0;
}

.tumfooter .misc li {
    margin-right: 2em;
    font-weight: bold;
}

.inline, .inline li {
    display: inline-block;
    margin: 0;
    padding: 0;
}



.tum-header {
    margin-top: -20px;
    padding: 0 50px 20px;
}

@media screen and (max-width: 620px) {
    .tum-header {
        padding: 0 20px 20px;
    }   
}

.tum-header p {
    font-size: small;
    font-weight: 400;
    line-height: 1.2;
}










.popup-body {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    background-color: white; 
    position: relative;   
    margin: auto;
}

#popup {
    width: 100vw;
    height: 100vh;    
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    background: repeating-linear-gradient(
        45deg,
        #eedd00,
        #eedd00 100px,
        #000000 100px,
        #000000 200px
    );
}



